import type { NewsArticle as NewsArticleInterface } from "@10x/cms/model/news-article";
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import type { Hit, InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { useIntersection } from "react-use";

import Intersection from "../Intersection";
import NewsArticle from "./NewsArticle";

function NewsArticleHits({
  hits,
  refineNext,
  hasMore,
}: InfiniteHitsProvided<Hit<NewsArticleInterface>>) {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {});

  const refineRef = useRef<((value?: string) => void) | null>(null);

  useEffect(() => {
    if (intersection?.isIntersecting && hasMore) {
      refineRef.current?.();
    }
  }, [intersection, hasMore]);

  useEffect(() => {
    refineRef.current = refineNext;
  }, [refineNext]);

  return (
    <ul
      css={css`
        list-style: none;
        padding-left: 0;
      `}
    >
      {hits.map((hit) => (
        <li key={hit.objectID}>
          <NewsArticle newsArticle={hit} />
        </li>
      ))}
      {/* This is super hacky, but when you first hit this component, no news articles will have appeared to push the intersection out of view,
so it'll immediately try to go to the second page. So, until the results come in, push the intersection out of view.
 */}
      {hits.length === 0 && (
        <div
          css={css`
            margin-bottom: 1000px;
          `}
        ></div>
      )}
      <Intersection ref={intersectionRef} />
    </ul>
  );
}
export default connectInfiniteHits(NewsArticleHits);
