import { useAccordion } from "@10x/hooks";
import {
  colorBlueDark,
  colorBlueMedium,
  colorSteelDarkest,
  colorSteelLighter,
  colorSteelMedium,
  spacing8,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { useLayoutEffect } from "react";

import AccordionToggle from "../../Accordion/AccordionToggle";
import Text from "../../Text";

interface Props {
  children: ReactNode;
  expanded?: boolean;
  label: string;
  onClick?: (expanded: boolean) => void;
  showIndicator?: boolean;
}

const CollapsibleMenu = ({
  children,
  expanded = false,
  label,
  onClick,
  showIndicator = false,
}: Props) => {
  const { active, contentRef, height, toggleAccordion } = useAccordion({
    activeInitial: expanded,
    children: undefined,
  });

  useLayoutEffect(() => {
    if (
      (expanded !== undefined && expanded !== active) ||
      (expanded === undefined && active === true)
    ) {
      toggleAccordion();
    }
  }, [active, expanded, toggleAccordion]);

  const handleClick = () => {
    if (onClick) {
      onClick?.(!expanded);
    } else {
      toggleAccordion();
    }
  };
  return (
    <div
      css={css`
        border-bottom: 1px solid ${colorSteelLighter};
        box-sizing: border-box;
        position: relative;
      `}
    >
      {showIndicator ? (
        <div
          css={css`
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: ${colorBlueMedium};
            position: absolute;
            left: -12px;
            top: 18px;
          `}
        />
      ) : null}
      <button
        css={css`
          background: none;
          border: none;
          color: ${colorSteelDarkest};
          cursor: pointer;
          margin: 0;
          padding: 0;
          width: 100%;

          &:hover {
            color: ${colorBlueDark};

            > div > .collapsible-menu-icon::before,
            > div > .collapsible-menu-icon::after {
              background-color: ${colorBlueDark};
            }
          }
        `}
        onClick={handleClick}
      >
        <div
          css={css`
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: ${spacing8} 0;
          `}
        >
          <Text as={"span"} color={"inherit"} size={"xsmall"} weight={"medium"}>
            {label}
          </Text>
          <AccordionToggle
            active={active}
            backgroundColor={colorSteelMedium}
            className={"collapsible-menu-icon"}
            size={"small"}
          />
        </div>
      </button>
      <div
        css={css`
          display: grid;
          justify-items: stretch;
          gap: ${spacing8};
          grid-template-columns: 1fr;
          max-height: ${height};
          overflow: hidden;
          padding-bottom: ${active ? spacing8 : "0"};
          transition: max-height 0.2s cubic-bezier(0.46, 0.01, 0.92, 0.77);
        `}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleMenu;
