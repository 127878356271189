/** React function component called ConnectedTable */

import type { VideoAlgoliaRecord } from "@10x/algolia-utils/types";
import { css } from "@emotion/react";
import type { Hit, InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-core";

import Intersection from "../Intersection";
import { useHitsFirstReceived, useInfiniteScroll } from "../Search/hooks";
import { tableColumns } from "./constants";
import type { ColumnType } from "../AlgoliaConnected/Table";
import Table from "../AlgoliaConnected/Table";

interface ConnectedTableProps
  extends InfiniteHitsProvided<Hit<VideoAlgoliaRecord>> {
  cache: any;
  onHits: () => void;
}

const VideosTable = ({
  hasMore,
  hits,
  refineNext,
  onHits,
}: ConnectedTableProps) => {
  useHitsFirstReceived(hits, onHits);
  const { intersectionRef } = useInfiniteScroll(hasMore, refineNext);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
      `}
    >
      <Table columns={tableColumns as ColumnType<any>[]} dataSource={hits} />
      {/** Place the infinite scroll intersection element at the bottom third of the table's height
       * so that we can load more results before the user reaches the bottom of the table.
       */}
      <Intersection
        css={css`
          bottom: 3000px;
          display: block;
          position: absolute;
          width: 100%;
        `}
        ref={intersectionRef}
      />
    </div>
  );
};

export default connectInfiniteHits<
  ConnectedTableProps,
  Hit<VideoAlgoliaRecord>
>(VideosTable);
