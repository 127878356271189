import type { TextColor } from "@10x/types";
import {
  colorBlueMedium,
  colorGrayLightest,
  colorGreyscale7,
  colorSteelDarkest,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import type { BackgroundColor } from "./types";

type NotificationBackgroundColor = Exclude<BackgroundColor, "purple">;

interface Props {
  /** Background field color **/
  background?: NotificationBackgroundColor;

  /** Link target, defaults to _blank  **/
  target?: "_self" | "_blank";

  /** Notification message text **/
  text?: string;

  /** Notification link url **/
  url?: string;
}

const backgrounds = {
  base: colorSteelDarkest,
  black: colorGreyscale7,
  blue: colorBlueMedium,
  gray: colorGrayLightest,
  white: colorWhite,
};

const linkColors: Record<NotificationBackgroundColor, TextColor> = {
  ["base"]: "white",
  ["black"]: "white",
  ["blue"]: "white",
  ["gray"]: "blue",
  ["white"]: "blue",
};

const Notification: FunctionComponent<Props> = ({
  background = "base",
  target = "_blank",
  text,
  url,
}) => {
  return (
    <div
      css={css`
        background: ${backgrounds[background]};
        display: inline-block;
        margin: 0;
        width: 100vw;
      `}
    >
      {url ? (
        <Text
          as={"div"}
          color={linkColors[background]}
          css={css`
            line-height: 1.1;
            text-align: center;
            transform: translateY(-2px);
          `}
          responsive={true}
          size={"medium"}
          weight={"medium"}
        >
          <Anchor
            color={"inherit"}
            css={{ display: "block", padding: "1rem 0" }}
            href={url}
            target={target}
          >
            {text}
            <Icon
              color={"inherit"}
              size={"11px"}
              source={"nav-right"}
              xPos={"11px"}
              yPos={"1px"}
            />
          </Anchor>
        </Text>
      ) : (
        <Text
          as={"div"}
          color={linkColors[background]}
          css={css`
            line-height: 1.1;
            padding: 1rem;
            text-align: center;
            transform: translateY(-2px);
          `}
          responsive={true}
          size={"medium"}
          weight={"medium"}
        >
          {text}
        </Text>
      )}
    </div>
  );
};

export default Notification;
