import StaticPropsContext from "@10x/site/contexts/static-props";
import { DISMISSED_LANGUAGE_SELECTOR_KEY } from "@10x/site/utils/cookie-keys";
import type { TextSize } from "@10x/types";
import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import {
  colorGrayLightest,
  colorGreyscale7,
  colorSteelDarkest,
  colorWhite,
  layoutWidth1200,
  linearGradientPurple,
  mediaTabletLandscape,
  radialGradientBlue,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import Cookies from "universal-cookie";

import Banner from "./Banner";
import Copy from "./Copy";
import type { HeroProps } from "./Hero";
import Hero from "./Hero";
import LanguageSelect from "./LanguageSelect";
import Notification from "./Notification";
import type { BackgroundColor, BackgroundMode, CTAButton } from "./types";

interface Props {
  /** A call-to-action definition. */
  action?: any;

  /** Background field color **/
  background: BackgroundColor;

  /** Padding for main masthead content */
  contentPadding?: string;

  /** Sets the width of Copy in percent **/
  copyWidth?: number;

  ctaButtons?: CTAButton[];

  /** Paragraph text. */
  description?: string;

  /** Properties for the eyebrow. */
  eyebrow?: {
    href?: string;
    text?: string;
  };

  eyebrowObject?: {
    href?: string;
    text?: string;
  };

  /** An optional message below the CTA. */
  footnote?: string;

  /** Adds a flexbox column-gap between Copy and Hero **/
  gap?: number;

  /** Very large text. */
  headline?: string | JSX.Element;

  headlineSize?: TextSize;

  /** A hero definition, either image or text. */
  hero?: HeroProps[];

  /** An optional min height to prevent page shift on hero load. */
  minHeight?: string;

  /**
   * Specifies white text on a blue background (dark mode)
   * or blue text on a white background (light mode);
   * */
  mode?: BackgroundMode;

  navigation: Navigation_FullFragment;

  /** An optional notification banner message with link . */
  notification?: {
    background?: Exclude<BackgroundColor, "purple">;
    target?: "_self" | "_blank";
    text: string;
    url: string;
  };

  optionalHeadline?: string | JSX.Element;

  /** Large text. */
  title?: string | JSX.Element;

  titleSize?: TextSize;

  /** An optional video item. */
  video?: any;
}

const backgrounds = {
  base: colorSteelDarkest,
  black: colorGreyscale7,
  blue: radialGradientBlue,
  gray: colorGrayLightest,
  purple: linearGradientPurple,
  white: colorWhite,
};

/**
 * A generic masthead component used for displaying company branding,
 * banner navigation, marketing copy, call-to-action inputs,
 * and hero content.
 *
 * Wrap inside Block for use as a page section.
 */
const Masthead: FunctionComponent<Props> = ({
  action,
  background,
  contentPadding,
  ctaButtons,
  description,
  eyebrow,
  eyebrowObject,
  footnote,
  gap = 0,
  headline,
  headlineSize,
  hero,
  copyWidth = hero?.[0]?.type === "image" ? 50 : 80,
  minHeight,
  navigation,
  notification,
  optionalHeadline,
  title,
  titleSize,
  video,
}) => {
  const staticProps = useContext(StaticPropsContext);

  const { width: windowWidth } = useWindowSize();
  const shouldRenderContent =
    action ||
    ctaButtons ||
    description ||
    eyebrow ||
    eyebrowObject ||
    headline ||
    hero ||
    optionalHeadline ||
    title ||
    video;
  const heroWidth = 100 - copyWidth;

  const paddingContent = contentPadding
    ? contentPadding
    : hero
    ? "72px 0"
    : "48px 0";

  const [parentHeight, setParentHeight] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);

  const [dismissedLanguage, setDismissedLanguage] = useState(false);

  const mode: Record<BackgroundColor, BackgroundMode> = {
    ["base"]: "dark",
    ["black"]: "dark",
    ["blue"]: "dark",
    ["gray"]: "light",
    ["purple"]: "dark",
    ["white"]: "light",
  };

  const parentElOffsetHeight = parentRef.current?.offsetHeight;

  useEffect(() => {
    if (typeof parentElOffsetHeight !== "undefined") {
      setParentHeight(parentElOffsetHeight);
    }
  }, [windowWidth, parentElOffsetHeight]);

  useEffect(() => {
    const cookies = new Cookies();
    setDismissedLanguage(cookies.get(DISMISSED_LANGUAGE_SELECTOR_KEY));
  }, [setDismissedLanguage]);

  return (
    <div
      className={"Masthead"}
      css={css`
        align-items: center;
        background: ${background && backgrounds[background]};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 1rem;
        width: 100%;
      `}
    >
      {staticProps?.showLanguageSelect && !dismissedLanguage ? (
        <LanguageSelect
          countryCode={staticProps.countryCode}
          onClose={() => setDismissedLanguage(true)}
        />
      ) : null}
      {notification?.text ? (
        <Notification
          background={notification.background}
          target={notification.target}
          text={notification.text}
          url={notification.url}
        />
      ) : null}

      <Banner mode={mode[background]} navigation={navigation} />

      {shouldRenderContent ? (
        <div
          css={css`
            align-items: flex-start;
            display: flex;
            height: 100%;
            ${minHeight ? `min-height: ${minHeight};` : ""};
            max-width: ${layoutWidth1200};
            padding: ${paddingContent};
            width: 100%;

            @media (max-width: ${mediaTabletLandscape}) {
              flex-direction: column;
              justify-content: center;
              padding: 0 0 16px 0;
            }
          `}
          ref={parentRef}
        >
          <Copy
            action={action}
            ctaButtons={ctaButtons}
            description={description}
            eyebrow={eyebrow || eyebrowObject}
            footnote={footnote}
            gap={gap}
            headline={headline || optionalHeadline}
            headlineSize={headlineSize}
            mode={mode[background]}
            title={title}
            titleSize={titleSize}
            video={video}
            width={copyWidth}
          />

          {hero?.[0]?.url ? (
            <Hero
              height={parentHeight}
              hero={hero[0]}
              mode={mode[background]}
              width={heroWidth}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Masthead;
