import { eventsIndexName } from "@10x/algolia-utils/index-names";
import type { FunctionComponent } from "react";

import Search, { InfiniteHits } from "../Search";
import Filters from "../Search/Filters";
import { EVENT_FACETS } from "./constants";
import EventCard from "./EventCard";
import PastEventsToggle from "./PastEventsToggle";

/**
 * A wrapper around the Search component,
 * in order to supply it with the required
 * props for event search.
 */
const EventSearch: FunctionComponent = () => {
  const excludeRefinements = (items: Array<any>) => {
    return items.filter((item) => item.attribute !== "timestamp");
  };

  return (
    <Search
      hitsRenderer={({ onHits }) => {
        return (
          <InfiniteHits
            hitRenderer={({ hit }) => {
              return <EventCard event={hit} />;
            }}
            onHits={onHits}
          />
        );
      }}
      includeStats={true}
      indexName={eventsIndexName.toString()}
      placeholder={"Search events"}
      sidebar={
        <div>
          <Filters
            excludeRefinements={excludeRefinements}
            facets={EVENT_FACETS}
            title={"Events"}
          />
          <PastEventsToggle attribute={"timestamp"} />
        </div>
      }
    />
  );
};

export default EventSearch;
