import { videosIndexName } from "@10x/algolia-utils/index-names";
import { useStorePreviousSearchParams } from "@10x/hooks";
import type { FunctionComponent } from "react";

import Search, { InfiniteHits } from "../Search";
import Filters from "../Search/Filters";
import { VIDEO_FACETS } from "./constants";
import VideoCard from "./VideoCard";

/**
 * A wrapper around the Search component,
 * in order to supply it with the required
 * props for video search.
 */
const VideoSearch: FunctionComponent = () => {
  useStorePreviousSearchParams();

  return (
    <Search
      hitsRenderer={({ onHits }) => {
        return (
          <InfiniteHits
            hitRenderer={({ hit }) => {
              return <VideoCard video={hit} />;
            }}
            onHits={onHits}
          />
        );
      }}
      includeStats={true}
      indexName={videosIndexName.toString()}
      placeholder={"Search videos"}
      sidebar={<Filters facets={VIDEO_FACETS} title={"Videos"} />}
    />
  );
};

export default VideoSearch;
