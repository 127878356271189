import { HEIGHT } from "@10x/ui/src/Support/TopBar";
import { compact } from "lodash-es";
import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";

const calculateClosestElement = (
  scrollTop: number,
  elements: HTMLElement[],
): HTMLElement => {
  let closest = elements[0];

  elements.forEach((element) => {
    if (
      Math.abs(
        (element?.offsetTop || 0) - (scrollTop + Number.parseInt(HEIGHT)),
      ) <
      Math.abs((closest.offsetTop || 0) - (scrollTop + Number.parseInt(HEIGHT)))
    ) {
      closest = element;
    }
  });

  return closest;
};

export const useScrollMenu = (
  sections: RefObject<{ [key: string]: HTMLElement | null }>,
): {
  activeNavItem: HTMLElement | null;
} => {
  const { y: scrollY } = useWindowScroll();

  const [closestSection, setClosestSection] = useState<HTMLElement | null>(
    null,
  );

  // we want the use effect to run anytime there was an update to the ref object
  useEffect(() => {
    if (Object.keys(sections).length <= 0) {
      setClosestSection(null);
    }

    setClosestSection(
      calculateClosestElement(
        scrollY,
        compact(Object.values(sections.current || {})),
      ),
    );
  }, [setClosestSection, sections, scrollY]);

  return {
    activeNavItem: closestSection,
  };
};

export default useScrollMenu;
