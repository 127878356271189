import type { MDXComponents as MDXComponentsType } from "mdx/types";

import Anchor from "../../Anchor";
import Blockquote from "../../Blockquote";
import Code from "../../Code";
import Figure from "../../Figure";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from "../../Heading";
import ListItem from "../../ListItem";
import Paragraph from "../../Paragraph";
import PreformattedText from "../../PreformattedText";
import Table, {
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../Table";
import ThematicBreak from "../../ThematicBreak";
import UnorderedList from "../../UnorderedList";
import MDXScope from "./MDXScope";

/**
 * Exports a set of component mappings for MDX:
 * https://mdxjs.com/getting-started#table-of-components
 *
 * The commented elements below don't have custom component
 * mappings, as they don't require styles. They are left in
 * the list so that we are clear on what which JSX elements
 * have custom MDX component mappings.
 */
export const MDXHtmlComponents = {
  a: Anchor,
  blockquote: Blockquote,
  code: Code,
  // delete: Delete,
  // em: Emphasis,
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  hr: ThematicBreak,
  img: Figure,
  inlineCode: Code,
  li: ListItem,
  // ol: OrderedList,

  p: Paragraph,
  pre: PreformattedText,
  // strong: Strong,
  table: Table,
  tbody: TableBody,
  td: TableDataCell,
  th: TableHeaderCell,
  thead: TableHead,
  thematicBreak: ThematicBreak,
  tr: TableRow,
  ul: UnorderedList,
};

const MDXComponents = {
  ...MDXHtmlComponents,
  ...MDXScope,
} as any as MDXComponentsType;

export default MDXComponents;
